import { _ } from "vue-underscore";
import Multiselect from 'vue-multiselect'
import Vue from 'vue';
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import CharacterCount from "@tiptap/extension-character-count";
import { Editor, EditorContent } from "@tiptap/vue-2";
import MenuBar from "./MenuBar.vue";
import Image1 from "@tiptap/extension-image";
import History from "@tiptap/extension-history";
export default {
  data: () => ({
    seoRegions: {
      id: 0,
      code: '',
      name: '',
      url_slug: "",
      priority: "",
      body: "",
      meta_title: "",
      meta_keywords: "",
      meta_description: "",
      logo_alt_text: "",
      og_tc_same_to_meta: false,
      head_scripts: "",
      body_scripts: "",
      logo_url: "",
      // logo_url: "",
      faqs: [{ question: '', answer: '' }],
    },
    selectedRegion: {
      code: '',
      name: ''
    },
    companyList: [],
    editor: null,
    company_id: "",
    isFullScreen: false,
    loading: false,
    webUrl: process.env.VUE_APP_WEB_URL,
    airlinesList: [],
    selectedAirline: [],
    regionsList: [],
    factsList: [],
    selectedRows: [],
    showAddFact: false,
    selectedFactIndex: null,
    facts: {
      id: 0,
      subject_id: "",
      title: "",
      description: ""
    },
    showAddFaq: false,
    selectedFaqIndex: null,
    faqsList: [],
    faq: {
      id: 0,
      subject_id: "",
      question: "",
      answer: ""
    },
    error: "",
    backUrl: "/seo-region"
  }),
  components: {
    Multiselect,
    EditorContent,
    MenuBar,
  },
  watch: {
    "seoRegions.og_tc_same_to_meta": function (value) {
      if (value) {
        this.seoRegions.og_tc_title = this.seoRegions.meta_title;
        this.seoRegions.og_tc_description = this.seoRegions.meta_description;
      } else {
        this.seoRegions.og_tc_title = this.seoRegions.og_tc_title;
        this.seoRegions.og_tc_description = this.seoRegions.og_tc_description;
      }
    },
    company_id: {handler(value) {
      if (value) {
        this.selectedRegion = {};
      } else {
        this.selectedRegion = {};
      }}
    },
    selectedRegion: {
      handler(newVal) {
        this.seoRegions.code = newVal.code;
        this.seoRegions.name = newVal.name;
      },
      deep: true,
    },
  },
  computed: {
    isSameOgTcValue() {
      return this.seoRegions.og_tc_same_to_meta ? 1 : 0;
    },
    getRules() {
      return this.seoRegions.og_tc_image ? "" : "required";
    },
    getDataTitle() {
      return this.imageName ? this.imageName : "Drag your image here";
    },
    wordCount() {
      if (this.seoRegions.post_body.trim() === "") {
        return 0;
      }
      return this.seoRegions.post_body.trim().split(/\s+/).length;
    },
    calculateReadingTime1() {
      const wordsPerMinute = 150;
      const minutes = Math.ceil(this.wordCount / wordsPerMinute);
      return minutes;
    },
    getregion() {
      this.code = this.seoRegions.code;
      this.name = this.seoRegions.name;
      return this.code + '-' + this.name
    }
  },
  methods: {
    updateIsSameOgTcValue(event) {
      this.seoRegions.og_tc_same_to_meta = event.target.checked;
    },
    cloneItem(index) {
      const newItem = {
        question: '',
        answer: '',
      };
      this.seoRegions.faqs.push(newItem);
    },
    removeItem(index) {
      if (this.seoRegions.faqs.length > 1) {
        this.seoRegions.faqs.splice(index, 1);
      }
    },
    calculateReadingTime(wordCount) {
      const wordsPerMinute = 150;
      const minutes = Math.ceil(wordCount / wordsPerMinute);
      return minutes;
    },
    calculateReadingTimeLabel(wordCount) {
      const minutes = this.calculateReadingTime(wordCount);
      return minutes === 1 ? "minute" : "minutes";
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      const editorElement = document.querySelector(".editor");
      if (editorElement) {
        if (this.isFullScreen) {
          editorElement.style.height = "100vh";
        } else {
          editorElement.style.height = "26rem";
        }
      }
    },
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
    initEditor() {
      this.seoRegions.body = "";

      this.editor = new Editor({
        content: this.seoRegions.body,
        extensions: [
          StarterKit.configure({
            history: false,
          }),
          Link.configure({
            openOnClick: false,
          }),
          CharacterCount,
          BulletList.configure({
            itemTypeName: "listItem",
            keepMarks: true,
            keepAttributes: true,
          }),
          ListItem,
          OrderedList,
          Image1,
          History,
        ],
        onUpdate: () => {
          this.seoRegions.body = this.editor.getHTML();
          this.$emit("input", this.seoRegions);
        },
      });
    },
    resetForm() {
      this.$refs.observer.reset();
      this.seoRegions = {
        id: 0,
        title: "",
        name: "",
        body: "",
        page_title: "",
        url_slug: "",
        head_section: "",
        body_section: "",
        priority: ""
      };
    },
    validateFaqs() {
      Number.isInteger(this.selectedFaqIndex)
        ? this.updateFaqs()
        : this.addFaqs();
    },

    


    selectImage(event) {
      let _vm = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        _vm.seoRegions.logo_url = input.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#imagePreview").css(
            "background-image",
            "url(" + e.target.result + ")"
          );
          $("#imagePreview").hide();
          $("#imagePreview").fadeIn(650);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    
    regionCreateOrUpdate() {
      let _vm = this;
      const com_id = _vm.company_id;
      let fd = new FormData();
      const airline = [];
      if (_vm.seoRegions.logo_url) {
        fd.append("logoImage", _vm.seoRegions.logo_url);
      }
      _vm.seoRegions.og_tc_same_to_meta = _vm.seoRegions.og_tc_same_to_meta ? 1 : 0;
      _vm.seoRegions.company_id = _vm.company_id;
      _vm.seoRegions.body = _vm.editor.getHTML();
      _vm.seoRegions.url_slug = _vm.seoRegions.url_slug.toLowerCase();

      Object.keys(_vm.seoRegions).forEach(key => {
        if (_vm.seoRegions[key] !== null) { // Exclude fields with null value
          if (key === 'faqs') {
            _vm.seoRegions.faqs.forEach((faq, index) => {
              fd.append(`faqs[${index}][question]`, faq.question);
              fd.append(`faqs[${index}][answer]`, faq.answer);
            });
          } else {
            fd.append(key, _vm.seoRegions[key]);
          }
        }
      });

      this.axios.post(`/seo/regions/`+com_id+`/update`, fd).then(function (response) {
        _vm.$router.push(_vm.backUrl);
      });
    },


    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/seo/regions/"+this.$route.params.company_id+'/'  + this.$route.params.id)
        .then(function (response) {
          _vm.seoRegions = response.data.data;
          _vm.seoRegions.body = response.data.data.body;
          _vm.company_id = _vm.seoRegions.company_id;
          
          _vm.value(_vm.seoRegions.body);
          if (_vm.seoRegions.full_image_url && _vm.seoRegions.full_image_url.length > 0) {
            $("#imagePreview").css("background-image",
              "url(" + _vm.seoRegions.full_image_url + "?date=" + Date.now() + ")"
            );
            $("#imagePreview").hide();
            $("#imagePreview").fadeIn(650);
          }
        })
        .catch(function () { });
    },

    closeFactForm(e) {
      e.preventDefault();
      document.getElementById('loginform').style.display = "none";
      this.showAddFact = false;

    },
    closeFaqForm(e) {
      e.preventDefault();
      document.getElementById('loginform1').style.display = "none";
      this.showAddFaq = false;

    },
    getRegion() {
      const _vm = this
      const com_id = _vm.company_id;
      this.axios
        .get(`seo/regions/`+com_id+`/distinct-list`, _vm.regionsList)
        .then(function (response) {
          _vm.regionsList = response.data.data
        })
        .catch(function () { })
    },
    updateUrl(event) {
      if (event.code === 'Space') {
        const inputText = event.target.value.replace(/\s{1,}/g, '-').toLowerCase();
        if (this.seoRegions.url_slug.length > 1 && this.seoRegions.url_slug[this.seoRegions.url_slug.length - 2] === '-') {
          this.seoRegions.url_slug = inputText;
        } else {
          this.seoRegions.url_slug = inputText;
        }
      }

    },
    getAirlinesCode() {
      let _vm = this;
      const com_id = _vm.company_id;
      
      this.axios
        .get(`/seo/airlines/`+com_id+`/`+`distinct-list` )
        .then(function (response) {
          _vm.airlinesList = response.data.data;
          if (parseInt(_vm.$route.params.id) > 0) {
            _vm.getDetail();
          }
        })
        .catch(function () {});
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.company_id =  _vm.company_id ? _vm.company_id : _vm.companyList[0].id;
          _vm.getRegion();
          // _vm.getAirlinesCode();
        })
        .catch(function () { });
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== "") {
      this.getDetail();
    }
    this.getCompany()
    // this.getAirlinesCode();    
    // window.onload = function () {
    //   document.getElementById("factsaddbtn").click();
    // };

    this.initEditor();
  }

};
